jQuery(document).ready(function (e) {            // On attend que la page soit chargée
    jQuery('.obflink').click(function (e) {    // On écoute le clic sur un lien obfusqué
        var t = jQuery(this);
        var link = atob(t.data('o'))        // On décode l'url
        window.open(link)                    // On renvoi l'utilisateur vers la page
    })

    jQuery('body').on('mouseover', '.headictnavigation .nav-link:not(.loaded)', function (e) {
        // skipped if already loaded
        let id = jQuery(e.target).parent().find('.headictnavigation_widget').children().attr('id');
        if (!id || id != 'headictnavigation_widget_loader') {
            return;
        }

        let url = new URL(jQuery(e.target).parent().data('headictnavigationAjaxUrl'));
        url.searchParams.append('tab', jQuery(e.target).parent().data('tabIndex'));
        if (jQuery(e.target).parent().data('loaded')) {
            return; 
        }
        fetch(url.toString())
        .then(response => response.json())
        .then(response => {
            let key = 'headictnavigation_widget_' + jQuery(e.target).parent().data('tabIndex');
            jQuery('#' + key).replaceWith(response[key]);
            carouselNavNoMobile();
        })

    })
})

function carouselNavNoMobile() {
    $(".carousel-nav").owlCarousel({
        dots: false,
        items: 6,
        margin: 18,
        slideTransition: "ease-in",
        dragEndSpeed: 50,
        nav: true,
        checkVisible: false,
        navText: [
          "<span class='nav-btn prev-slide'><i class='icon-headict icon-headict-direction-circle-left'></i></span>",
          "<span class='nav-btn next-slide'><i class='icon-headict icon-headict-direction-circle-right'></i></span>"
        ],
        responsive: {
          0: {
            items: 1,
            stagePadding: 50,
            nav: false,
            center: false,
            loop: false
          },
          768: {
            items: 2
          },
          992: {
            items: 3
          },
          1199: {
            items: 6
          }
        }
      });
}